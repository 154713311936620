import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Card, CardContent, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BuildIcon from '@mui/icons-material/Build';

const ActivityLog = ({ projectId }) => {
  const [activityLogs, setActivityLogs] = useState([]);

  useEffect(() => {
    if (!projectId) return;

    const fetchActivityLogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/activitylog`);
        // Filter logs to match the current projectId and sort in descending order by timestamp
        const filteredLogs = response.data
          .filter((log) => {
            try {
              const message = JSON.parse(log.activityMessage);
              return message.projectId === projectId || message.ProjectId === projectId;
            } catch (error) {
              console.error("Error parsing activity message:", error);
              return false;
            }
          })
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setActivityLogs(filteredLogs);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      }
    };

    // Initial fetch
    fetchActivityLogs();

    // Polling every second
    const intervalId = setInterval(() => {
      fetchActivityLogs();
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [projectId]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="h6" sx={{ marginTop: '16px', marginBottom: '12px', fontSize: '1.25rem', fontWeight: 500 }}>
        Activity Log
      </Typography>
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          padding: '8px 16px',
          height: 0,
          boxSizing: 'border-box',
        }}
      >
        {projectId ? (
          activityLogs.length > 0 ? (
            activityLogs.map((log) => {
              let parsedMessage;
              try {
                parsedMessage = JSON.parse(log.activityMessage);
              } catch (error) {
                console.error("Error parsing activity message:", error);
                return null;
              }

              let activityTypeText = "Unknown activity type";
              let activityIcon;

              switch (log.activityTypeId) {
                case 1:
                  activityTypeText = "New algorithm plan data received";
                  activityIcon = parsedMessage.planAccepted ? (
                    <CheckCircleIcon sx={{ color: 'green', marginRight: '8px', fontSize: '1rem' }} />
                  ) : (
                    <CancelIcon sx={{ color: '#a31a27', marginRight: '8px', fontSize: '1rem' }} />
                  );
                  break;
                case 2:
                  activityTypeText = parsedMessage.action || "Configuration updated";
                  activityIcon = (
                    <BuildIcon sx={{ color: '#757575', marginRight: '8px', fontSize: '1rem' }} />
                  );
                  break;
                case 3:
                  activityTypeText = "Algorithm state updated";
                  activityIcon = (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color: '#757575',
                        marginRight: '8px',
                        fontSize: '18px',
                      }}
                    >
                      α
                    </Typography> // Greek alpha symbol for algorithm state updates
                  );
                  break;
                default:
                  activityTypeText = "Unknown activity type";
                  activityIcon = <CheckCircleIcon sx={{ color: '#757575', marginRight: '8px', fontSize: '1rem' }} />;
                  break;
              }

              // Format the JSON to be more readable in the tooltip
              const formattedMessage = parsedMessage
                ? JSON.stringify(parsedMessage, null, 2) // Pretty print JSON with 2 spaces
                : '';

              return (
                <Card
                  key={log.id}
                  sx={{
                    marginBottom: '8px',
                    borderRadius: '8px',
                    width: '100%',
                    boxShadow: 'none',
                    border: '1px solid #E0E0E0',
                    boxSizing: 'border-box',
                  }}
                >
                  <CardContent sx={{ padding: '12px' }}>
                    <Box display="flex" alignItems="center" marginBottom="4px">
                      <Tooltip title={parsedMessage.reason || ''}>
                        {activityIcon}
                      </Tooltip>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, fontSize: '0.9rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        {new Date(log.timestamp).toLocaleString()}
                      </Typography>
                    </Box>
                    <Typography
                      color="textSecondary"
                      sx={{ fontSize: '0.75rem', marginBottom: '4px' }}
                    >
                      {activityTypeText}
                    </Typography>
                    <Tooltip title={<pre style={{ whiteSpace: 'pre-wrap' }}>{formattedMessage}</pre>}>
                      <Typography
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '0.75rem',
                        }}
                      >
                        {`Message: ${formattedMessage}`}
                      </Typography>
                    </Tooltip>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <Typography sx={{ fontSize: '0.75rem', color: '#555' }}>
              No activity logs available for the selected project.
            </Typography>
          )
        ) : (
          <Typography sx={{ fontSize: '0.75rem', color: '#555' }}>
            Select a project to view the activity log.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ActivityLog;
