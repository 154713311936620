import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import DeviceApp from './components/DeviceApp';
import ProjectDetail from './components/ProjectDetail';
import posthog from 'posthog-js'

posthog.init('phc_5rIn0uVI3TKPMwOAtQrqJHf2abK5QFG7JCUxl3nOK82',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always'
    }
)

const App = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    // Use the environment variable for the API URL, with fallback to localhost for local development
    const apiUrl = process.env.REACT_APP_API_URL;

    axios
      .get(`${apiUrl}/projects`)
      .then((response) => {
        const projectList = response.data;
        setProjects(projectList);
        if (projectList.length > 0) {
          setSelectedProject(projectList[0]); // Set the first project as the selected project on load
        }
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
      });
  }, []);

  const handleSelectProject = (project) => {
    setSelectedProject(project);
  };

  const handleAddProject = () => {
    // Create a new project placeholder with default name "New Project"
    const newProject = {
      name: 'New Project',
      intersectionName: '',
      algorithm: '',
      algorithmEnabled: true,
    };
    setSelectedProject(newProject);
  };

  const handleSaveProject = (projectData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    if (projectData.id) {
      // Update an existing project
      axios
        .put(`${apiUrl}/projects/${projectData.id}`, projectData)
        .then(() => {
          setProjects((prevProjects) =>
            prevProjects.map((proj) => (proj.id === projectData.id ? projectData : proj))
          );
          setSelectedProject(projectData); // Update the selected project with new data
        })
        .catch((error) => {
          console.error('Error updating project:', error);
        });
    } else {
      // Create a new project
      axios
        .post(`${apiUrl}/projects`, projectData)
        .then((response) => {
          const newProject = response.data;
          setProjects((prevProjects) => [...prevProjects, newProject]);
          setSelectedProject(newProject); // Set the newly created project as the selected project
        })
        .catch((error) => {
          console.error('Error creating project:', error);
        });
    }
  };

  return (
    <Router>
      <DeviceApp
        projects={projects}
        selectedProject={selectedProject}
        onSelectProject={handleSelectProject}
        onAddProject={handleAddProject}
        onSaveProject={handleSaveProject}
        ProjectDetailComponent={
          <ProjectDetail project={selectedProject} onSave={handleSaveProject} />
        }
      />
    </Router>
  );
};

export default App;
