import React from 'react';
import { Box, Typography, List, ListItem, ListItemButton, IconButton, Divider } from '@mui/material';
import { ReactComponent as ArterialsLogo } from './arterials.svg';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import ActivityLog from './ActivityLog';
import ProjectDetail from './ProjectDetail';

const DeviceApp = ({
  projects,
  selectedProject,
  onSelectProject,
  onAddProject,
  onSaveProject,
}) => {
  return (
    <Box display="flex" height="100vh" overflow="hidden">
      {/* Far Left Column */}
      <Box
        sx={{
          width: 64,
          backgroundColor: '#F6F6F6',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '16px',
          paddingBottom: '16px',
          borderRight: '1px solid #E0E0E0',
          justifyContent: 'space-between', // Space between top and bottom
        }}
      >
        {/* Logo at the top */}
        <ArterialsLogo style={{ width: '40px', height: '40px', marginBottom: '16px' }} />

        {/* Link to Documentation at the bottom */}
        <IconButton
          href="/docs"
          sx={{
            color: 'rgba(0, 0, 0, 0.54)', // Adjust color as needed
          }}
        >
          <DescriptionIcon />
        </IconButton>
      </Box>

      {/* Middle Column */}
      <Box
        sx={{
          width: 320,
          backgroundColor: '#FAFAFA',
          padding: '0px',
          borderRight: '1px solid #E0E0E0',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              margin: '0px',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
              fontFamily: '"Inter", "Segoe UI", "Helvetica Neue", Arial, sans-serif',
            }}
          >
            Projects
          </Typography>
          <IconButton onClick={onAddProject}>
            <AddIcon />
          </IconButton>
        </Box>
        <List sx={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '10px', paddingRight: '10px' }}>
          {projects.map((project) => (
            <ListItem key={project.id} disablePadding>
              <ListItemButton
                onClick={() => onSelectProject(project)}
                sx={{
                  borderRadius: '8px',
                  paddingLeft: '16px',
                  marginBottom: '8px',
                }}
              >
                <Typography sx={{ color: 'rgb(19, 164, 159)', fontFamily: '"Inter", sans-serif' }}>
                  {project.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Right Hand Column */}
      <Box sx={{ flex: 1, padding: '0', display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
        {/* Section Title */}
        <Box
          sx={{
            padding: '16px',
            borderBottom: '1px solid #E0E0E0',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              margin: '0px',
              fontSize: '1.5rem',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.75rem',
              fontFamily: '"Inter", "Segoe UI", "Helvetica Neue", Arial, sans-serif',
            }}
          >
            {selectedProject ? selectedProject.name : 'Select a Project'}
          </Typography>
        </Box>

        {/* Split Columns for Project Detail and Activity Log */}
        <Box sx={{ display: 'flex', flex: 1, height: '100%', overflow: 'hidden' }}>
          {/* Project Form */}
          <Box sx={{ flex: 1, padding: '16px', overflowY: 'auto' }}>
            <ProjectDetail project={selectedProject} onSave={onSaveProject} />
          </Box>

          {/* Vertical Divider */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderRight: '1px solid #E0E0E0',
              height: '100%',
              alignSelf: 'stretch',
            }}
          />

          {/* Activity Log */}
          <Box sx={{ flex: 1, padding: '16px', overflowY: 'auto' }}>
            {selectedProject && <ActivityLog projectId={selectedProject.id} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceApp;
