import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Autocomplete, Checkbox, FormControlLabel, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as IntersectionIcon } from './intersection.svg';
import mockIntersections from '../data/mockIntersectionsZen60.json';  // Zen60 ID for M3210/CTLR
// import mockIntersections from '../data/mockIntersections.json';  // Prod gateway IDs
import axios from 'axios';

// Mock JSON payload for available algorithms
const mockAlgorithms = [
  { id: 1, name: 'Traffic Flow Optimisation v1.0' }
];

// Custom Styled Checkbox
const CustomCheckbox = styled(Checkbox)({
  color: '#19857b',
  '&.Mui-checked': {
    color: '#19857b',
  },
  '& .MuiSvgIcon-root': {
    '&:hover': {
      backgroundColor: 'transparent', // Remove the hover circle
    },
  },
});

const ProjectDetail = ({ project, onSave }) => {
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    intersectionName: '',
    intersectionId: null,
    algorithm: '',
    algorithmEnabled: true,
  });

  const [originalFormData, setOriginalFormData] = useState({});
  const [filteredIntersections, setFilteredIntersections] = useState([]);

  useEffect(() => {
    if (project) {
      const initialData = {
        id: project.id || null, // Preserve the ID if editing an existing project
        name: project.name || '',
        intersectionName: project.intersectionName || '',
        intersectionId: project.intersectionId || null,
        algorithm: project.algorithm || '',
        algorithmEnabled: project.algorithmEnabled !== undefined ? project.algorithmEnabled : true,
      };
      setFormData(initialData);
      setOriginalFormData(initialData); // Keep a copy of the original form data for comparison
    } else {
      const initialData = {
        id: null, // Reset ID if creating a new project
        name: '',
        intersectionName: '',
        intersectionId: null,
        algorithm: '',
        algorithmEnabled: true,
      };
      setFormData(initialData);
      setOriginalFormData(initialData);
    }
  }, [project]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleIntersectionChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      intersectionName: newValue ? newValue.name : '',
      intersectionId: newValue ? newValue.id : null,
    }));
  };

  const handleAlgorithmChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      algorithm: newValue ? newValue.name : '',
    }));
  };

  const handleAlgorithmEnabledChange = (event) => {
    const algorithmEnabled = event.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      algorithmEnabled,
    }));
  };

  const handleSave = async () => {
    // Make sure only the necessary data is sent to the backend
    const dataToSave = formData.id ? formData : { ...formData, id: undefined };
    onSave(dataToSave);

    // Determine what changes were made
    const isAlgorithmEnabledChanged = originalFormData.algorithmEnabled !== formData.algorithmEnabled;
    const otherChangesMade = Object.keys(formData).some(
      (key) => key !== 'algorithmEnabled' && formData[key] !== originalFormData[key]
    );

    // Log the activity based on the type of changes made
    if (isAlgorithmEnabledChanged && !otherChangesMade) {
      // If only the algorithm enable state changed, log type 3 activity
      const activityMessage = {
        action: formData.algorithmEnabled ? "Algorithm enabled" : "Algorithm disabled",
        projectId: formData.id || 'new',
      };
      await logActivity(3, activityMessage);
    } else if (otherChangesMade) {
      // If any other changes were made, log type 2 activity
      const configUpdateMessage = {
        action: "Configuration updated",
        projectId: formData.id || 'new',
      };
      await logActivity(2, configUpdateMessage);
    }
  };

  const logActivity = async (activityTypeId, activityMessage) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/activitylog`, {
        activityTypeId,
        activityMessage: JSON.stringify(activityMessage), // Convert activityMessage to JSON string
        timestamp: new Date().toISOString(), // Ensure timestamp is included in the request
      });
    } catch (error) {
      console.error("Error logging activity:", error);
    }
  };

  const handleInputChange = (event, value) => {
    if (value.length === 0) {
      setFilteredIntersections([]); // Clear filtered options if there's no input
    } else {
      // Filter the intersections based on the user's input and limit the results to top 10 hits
      const filtered = mockIntersections
        .filter((intersection) =>
          intersection.name.toLowerCase().includes(value.toLowerCase()) ||
          intersection.description.toLowerCase().includes(value.toLowerCase())
        )
        .slice(0, 5);
      setFilteredIntersections(filtered);
    }
  };

  return (
    <Box sx={{ padding: '16px' }}>
      {project ? (
        <>
          <Typography variant="h6" sx={{ marginBottom: '12px', fontSize: '1.25rem', fontWeight: 500 }}>
            Project Details
          </Typography>

          {/* Name Field */}
          <Box sx={{ marginBottom: '12px' }}>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '4px', fontSize: '0.875rem' }}>
              Name
            </Typography>
            <TextField
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter Project Name"
              variant="outlined"
              fullWidth
              sx={{
                marginBottom: '4px',
                backgroundColor: '#F8F8F8',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C4C4C4',
                  },
                  '& input': {
                    padding: '6px 8px',
                  },
                  fontSize: '0.875rem',
                  minHeight: '36px',
                },
              }}
              InputProps={{
                sx: { fontSize: '0.875rem', height: '36px' },
              }}
            />
          </Box>

          <Typography variant="h6" sx={{ marginBottom: '12px', fontSize: '1.25rem', fontWeight: 500 }}>
            Project Data
          </Typography>

          {/* Intersection Name Field */}
          <Box sx={{ marginBottom: '12px' }}>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '4px', fontSize: '0.875rem' }}>
              Intersection Name
            </Typography>
            <Autocomplete
              value={mockIntersections.find((option) => option.name === formData.intersectionName) || null}
              onChange={handleIntersectionChange}
              options={filteredIntersections}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Card
                  component="li"
                  {...props}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '2px',
                    padding: '2px',
                    width: '100%',
                    height: '60px',
                    boxShadow: 'none',
                    border: 'none',
                  }}
                >
                  <IntersectionIcon
                    style={{ width: '20px', height: '20px', marginRight: '8px', alignSelf: 'center' }}
                  />
                  <CardContent
                    sx={{
                      paddingLeft: '8px',
                      paddingTop: '22px',
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{option.name}</Typography>
                    <Typography sx={{ color: '#757575', fontSize: '0.75rem' }}>{option.description}</Typography>
                  </CardContent>
                </Card>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Intersection Name"
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginBottom: '4px',
                    backgroundColor: '#F8F8F8',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C4C4C4',
                      },
                      '& input': {
                        padding: '6px 8px',
                      },
                      fontSize: '0.875rem',
                      minHeight: '36px',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: { fontSize: '0.875rem', height: '36px' },
                  }}
                />
              )}
              sx={{
                '& .MuiAutocomplete-inputRoot': {
                  fontSize: '0.875rem',
                  padding: '6px 8px',
                },
              }}
            />
          </Box>

          {/* Algorithm Field */}
          <Box sx={{ marginBottom: '12px' }}>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '4px', fontSize: '0.875rem' }}>
              Algorithm
            </Typography>
            <Autocomplete
              value={mockAlgorithms.find((option) => option.name === formData.algorithm) || null}
              onChange={handleAlgorithmChange}
              options={mockAlgorithms}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
                  <Typography sx={{ marginRight: '12px', fontSize: '1rem' }}>α</Typography> {/* Alpha symbol */}
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Algorithm Name"
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginBottom: '4px',
                    backgroundColor: '#F8F8F8',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C4C4C4',
                      },
                      '& input': {
                        padding: '6px 8px',
                      },
                      fontSize: '0.875rem',
                      minHeight: '36px',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: { fontSize: '0.875rem', height: '36px' },
                  }}
                />
              )}
            />
          </Box>

          {/* Algorithm Enabled Checkbox */}
          <Box sx={{ marginBottom: '12px' }}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={formData.algorithmEnabled}
                  onChange={handleAlgorithmEnabledChange}
                />
              }
              label="Algorithm Enabled"
              sx={{ fontWeight: 'bold', fontSize: '0.25rem' }}
            />
          </Box>

          {/* Save Button */}
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              fontSize: '0.875rem',
              padding: '8px 16px',
              backgroundColor: '#19857b',
              color: '#FFFFFF',
              height: '35px',
              width: '100px',
              '&:hover': {
                backgroundColor: '#176f69',
              },
            }}
          >
            Save
          </Button>
        </>
      ) : (
        <Typography sx={{ fontSize: '0.875rem' }}>No project selected</Typography>
      )}
    </Box>
  );
};

export default ProjectDetail;
